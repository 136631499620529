<template>
    <div class="pItem"><span v-on:click="$bvModal.show(modal_id)">
            <b-card :title="`${this.promotion_data.name} x${this.promotion_data.ticket_quanlity}`"
                :img-src="this.imageSource" img-alt="Image" img-top="img-top" img-height="200"
                @error="setErrorImg"></b-card></span>
        <b-modal :id="modal_id" :title="this.promotion_data.name" hide-footer="hide-footer">
            <b-row>
                <b-col><img :src="this.imageSource" width="100%" /></b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h4>Point</h4>
                    <p>{{ getPoint() }}</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h4>Type</h4>
                    <p>{{ getPromotionType() }}</p>
                </b-col>
                <b-col>
                    <h4>Value</h4>
                    <p>{{ readPromotionValue() }}</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h4>Limit Remaining</h4>
                    <p>{{ getLimit() }}</p>
                </b-col>
                <b-col>
                    <h4>Minimum Apply Quantity</h4>
                    <p>{{ getMinimumTotal() }}</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h4>Expired From</h4>
                    <p>{{ formatDateTime(this.promotion_data.expired_date_from) }}</p>
                </b-col>
                <b-col>
                    <h4>Expired To</h4>
                    <p>{{ formatDateTime(this.promotion_data.ticket_expired_to) }}</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p>{{ this.promotion_data.detail }}</p>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>

import moment from "moment";
import Constants ,{ PROMOTION } from "../common/constant";


export default {
    name: "PromotionItem",

    props: {
        promotion_id: {
            type: Number,
            required: true,
        },
        promotion_data: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            modal_id: 'modal-id-' + this.promotion_id,
        };
    },

    computed: {
        imageSource: function () {
            if (this.promotion_data?.image?.full_original_url) {
                console.log(this.promotion_data.image.full_original_url);
                return this.promotion_data.image.full_original_url;
            }

            return Constants.TEMP_IMAGE;
        },
    },

    created() {
    },

    methods: {
        formatDateTime(value) {
            return moment(value).utcOffset('+0700').format('YYYY-MM-DD HH:mm');
        },
        readPromotionValue() {
            if (this.promotion_data.pmt_type == PROMOTION.DISCOUNT.PERCENT) {
                return this.promotion_data.pmt_type_detail + "%";
            } else if (this.promotion_data.pmt_type == PROMOTION.DISCOUNT.CASH_VOUCHER) {
                var value = this.promotion_data.pmt_type_detail;
                return value.toLocaleString('it-IT', { style: 'currency', currency: 'VND' });
            }
        },
        getPoint() {
            if (this.promotion_data.trade_by_value) {
                let point = parseInt(this.promotion_data.trade_by_value);
                return typeof (point) === 'number' ? point : 0;
            } else {
                return 0;
            }
        },
        getPromotionType() {
            var type = this.promotion_data.pmt_type;
            if (type) {
                if (type == PROMOTION.DISCOUNT.CASH_VOUCHER) {
                    return "cashVoucher";
                } else if (type == PROMOTION.DISCOUNT.PERCENT) {
                    return "Percent";
                } else if (type == PROMOTION.DISCOUNT.MONEY) {
                    return "Money";
                } else if (type == PROMOTION.DISCOUNT.FREE_POINT) {
                    return "Free Point";
                }
            } else {
                return '';
            }
        },
        getLimit() {
            if (this.promotion_data.limit) {
                var value = this.promotion_data.limit;
                return value.toLocaleString('it-IT', { style: 'currency', currency: 'VND' });
            } else {
                return '0';
            }
        },
        getMinimumTotal() {
            if (this.promotion_data.minimum_total) {
                var value = this.promotion_data.minimum_total;
                return value.toLocaleString('it-IT', { style: 'currency', currency: 'VND' });
            } else {
                return '0';
            }
        },
    },
};
</script>
