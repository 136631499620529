<template>
    <div>
        <main-layout></main-layout>
        <v-container class="wrapper">
            <v-form>
                <h2 class="text-center">Your Promotion</h2>
                <div class="order-history__condition">
                    <input id="toggle-promotion-owned" name="toggle-promotion-owned" type="radio" :checked="condition == 'owned_promotions'" @click="setCondition('owned_promotions')"/>
                    <label class="order all" for="toggle-promotion-owned" :class="condition == 'owned_promotions' ? 'active' : ''">
                        Owned</label>
                    <input id="toggle-promotion-used" name="toggle-promotion-used" type="radio" :checked="condition == 'used_promotions'" @click="setCondition('used_promotions')"/>
                    <label class="order all" for="toggle-promotion-used" :class="condition == 'used_promotions' ? 'active' : ''">
                        Used</label>
                    <input id="toggle-promotion-expired" name="toggle-promotion-expired" type="radio" :checked="condition == 'expired_promotions'" @click="setCondition('expired_promotions')"/>
                    <label class="order all" for="toggle-promotion-expired" :class="condition == 'expired_promotions' ? 'active' : ''">
                        Expired</label>
                </div>
                <div>
                    <div class="mb-5" deck="deck">
                        <PromotionItem class="col-md-4 col-12 p-2 promotionItem" v-for="(promotion) of this.promotions" :key="promotion.pk" :promotion_id="promotion.id" :promotion_data="promotion"></PromotionItem>
                    </div>
                </div>
            </v-form>
        </v-container>
    </div>
</template>
<script>
// import { mapActions, mapGetters } from "vuex";
import MainLayout from "@/layout/MainLayout.vue";
import screen from "@/mixins/screen.js";
import { mapGetters } from "vuex";
import PromotionItem from "../components/PromotionItem.vue";
import { PROMOTION } from "../common/constant"
// import store from "../store

export default {
    name: "YourPromotion",
    components: {
        PromotionItem,
        MainLayout
    },
    
    mixins: [screen],

    // beforeRouteEnter(to, from, next) {
    //     window.scrollTo(0, 0);
    //     if (!store.state.auth.currentUser) {
    //         next("/");
    //     } else {
    //         next();
    //     }
    // },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            loading: false,
            condition: 'owned_promotions',
        };
    },



    computed: {
        ...mapGetters({
            authDataGet: "auth/authDataGet",
            userOrder: "auth/userOrder",
            promotions: "customer/promotionList",
            deliveryDataGet: "deliveryDataGet",
        })
    },

    // created() {
    //      this.setCondition('owned_promotions');
    // },

    mounted() {
        this.setCondition('owned_promotions');
    },

    methods: {
        async setCondition(condition) {
            this.loading = true;
            this.condition = condition;
            const payload = {
                code_type: 'cus',
                code_value: this.authDataGet.id,
                status: this.condition,
                code: PROMOTION.PREFIX_CUSTOMER + this.authDataGet.id,
                cart: {
                    items: []
                },
                stores: [{
                    store_code: "003001",
                    brand_code: "4003"
                }]
            }
            console.log('payload', payload)
            await this.$store.dispatch('customer/invidualPromotion', payload);
            console.log(this.promotions);
            this.loading = false;
        },
    },
};
</script>